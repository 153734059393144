<style scoped>
.row-border td, .row-border th, .row-border tr:last-child td {
  border: 1px solid #EFF2F5 !important;
}
</style>
<template>
  <div class="col-xl-12 mb-5 mb-xl-10">
    <div class="card card-flush h-xl-100">
      <div class="card-header pt-4">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">Order Products</span><span class="text-muted mt-1 fw-bold fs-7">by due date</span>
        </h3>
        <div class="card-toolbar">
          <div class="row justify-content-end">
            <div class="col-md-5">
              <el-select size="large" filterable :placeholder="generalConstants.SELECTTRADINGPARTNER" v-model="filterData.searchByStore" @change="getJobBoard()">
                <el-option :label="tradingData.store_name" :value="tradingData.trading_partner_id" :key="tradingData" v-for="tradingData in tradingPartnerData"></el-option>
              </el-select>
            </div>
            <div class="col-md-3">
              <el-select size="large" v-model="filterData.days" class="w-100 ml-4" placeholder="Select Days" @change="selectDays">
                <el-option
                  v-for="(days, index) in 7"
                  :key="'user'+days"
                  :label="days+(index==0 ? ' Day' : ' Days')"
                  :value="days"
                />
              </el-select>
            </div>
            <div class="col-md-1">
              <el-tooltip class="box-item" effect="dark" :content="generalConstants.REFRESH" placement="top">
                <button type="button" @click="getJobBoard" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table align-middle w-100 table-row-dashed fs-6 gy-4 gs-4 row-border" v-loading="loading" >
            <thead>
              <tr class="text-start text-gray-600 fw-bolder fs-7 text-uppercase gs-2">
                <th class="text-left">Status</th>
                <th class="text-left min-w-100px" v-for="(date, index) in jobBoardData" :key="index">
                  <span v-if="dateIsValid(index)">{{formatDate(index)}}</span>
                  <span v-else>{{index}}</span>
                </th>
              </tr>
            </thead>
            <tbody class="fw-bolder text-gray-600">
              <tr
                v-for="(status, index) in statusData"
                :key="index"
              >
                <td class="min-w-100px">
                  <span
                    :class="'badge py-3 px-4 fs-7 badge-'+status.badge">
                    {{status.title}}
                  </span>
                </td>
                <td
                  class="align-top min-w-100px"
                  v-for="(date, index) in jobBoardData"
                  :key="index" @drop="onDrop($event, index, status)"
                  @dragover.prevent
                  @dragenter.prevent
                >
                <template v-if="permission.isOrderViewAllowed">
                  <a
                    @click="detailPage('/orders/' +product.order_id+'/' +product.product_id)"
                    href="javascript:void(0)"
                    :draggable="permission.isEditAllowed"
                    @dragstart="startDrag($event, index, product)"
                    v-for="(product, productindex) in date[status.title]"
                    :key="productindex"
                  >
                    <span
                      :class="'mb-2 badge py-2 px-3 fs-9 badge-'+product.order_product_status_badge"
                    >
                      {{product.formated_id}}
                    </span>&nbsp;
                  </a>
                </template>
                <template v-else>
                    <a
                    href="javascript:void(0)"
                    :draggable="permission.isEditAllowed"
                    @dragstart="startDrag($event, index, product)"
                    v-for="(product, productindex) in date[status.title]"
                    :key="productindex"
                  >
                    <span
                      :class="'mb-2 badge py-2 px-3 fs-9 badge-'+product.order_product_status_badge"
                    >
                      {{product.formated_id}}
                    </span>&nbsp;
                  </a>
                </template>
                  
                </td>
              </tr>
            </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import globalConstant from "@/core/data/globalConstant.js";
import { useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatDate } from "@/core/helpers/common.ts";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { useRoute } from "vue-router";
import { formatText } from "@/core/helpers/common";
export default {
  name: "job-board",
  setup() {
    const total = ref(null);
    const jobBoardData = ref([]);
    const statusData = ref([]);
    const loading = ref(false);
    const refreshLoader = ref(false);
    const router = useRouter();
    const generalConstants = globalConstant.general;
    const tradingPartnerData = reactive([]);
    const route = useRoute();
    const permission = reactive({
      isEditAllowed: false,
      isOrderViewAllowed: false
    });
    const filterData = reactive({
      searchByStore: "",
      days: 4,
    });
    let columnHeader = "";
    const changedElement = ref([]);
    const startDrag = async (evt, date, item) => {
      columnHeader = date;
      changedElement.value = item;
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.product_id);
    };
    const onDrop = async (evt, index, status) => {
      if (columnHeader != index || changedElement.value.order_product_status_title == status.title) {
        return false;
      }
      let response = await updateOrderProductData(status, index);
      if(response.status){
        if (response.status == "success") {
          await getJobBoard();
          notificationFire(response.message, "success");
        } else {
          notificationFire(response.message, "error");
        }
        loading.value = false;
      }
    };

    const detailPage = async (url) => {
      window.open(url, "_blank");
    }

    //Update order status after drag and drop
    const updateOrderProductData = (status, index) => {
      loading.value = true;
      return ApiService.post("orderproductstatus/update-order-product-status" ,
        {
          productId: changedElement.value.product_id,
          statusId: status.id,
          orderId: changedElement.value.order_id
        }
      )
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          loading.value = false;
          let message = "";
          if (typeof error.response !== "undefined") {
            message = error.response.data.message;
          } else if (typeof error.message !== "undefined") {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
    };
    //Check date is valid or not
    const dateIsValid = (dateStr) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateStr.match(regex) === null) {
        return false;
      }
      const date = new Date(dateStr);
      const timestamp = date.getTime();
      if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
      }
      return date.toISOString().startsWith(dateStr);
    };
    //Days filter function
    const selectDays = async (value) => {
      filterData.days = value;
      getJobBoard();
    };

    const getTradingPartners = async () => {
      await ApiService.query("get-active-trading-partners")
        .then(({ data }) => {
          tradingPartnerData.push({
            trading_partner_id: 0,
            store_name: "All Trading Partner",
          });
          data.data.forEach((obj) => {
            tradingPartnerData.push({
              trading_partner_id: obj.trading_partner_id,
              store_name: obj.get_user_oauth[0].name,
            });
          });
          filterData.searchByStore = 0;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };

    //Get job board data
    const getJobBoard = async () => {
      loading.value = true;
      refreshLoader.value = true;
      let searchParams = {
        days: filterData.days,
        store_id: filterData.searchByStore,
      };
      await ApiService.query("job-board", { params: searchParams })
        .then(({ data }) => {
          total.value = 0;
          if (typeof data.data != "undefined" && data.data != null) {
            jobBoardData.value = data.data.jobboard_data;
            statusData.value = data.data.status_data;
            total.value = data.data.order_product_count;
          } else {
            total.value = 0;
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
          refreshLoader.value = false;
        })
        .catch((error) => {
          let message = "";
          jobBoardData.value = [];
          total.value = 0;
          loading.value = false;
          refreshLoader.value = false;
          if (typeof error.response !== "undefined") {
            message = error.response.data.message;
          } else if (typeof error.message !== "undefined") {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
    };
    //Set breadcrumbs and get the orders
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      await getTradingPartners();
      await getJobBoard();
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("updateJobBoard") || role == "Super Admin") {
        permission.isEditAllowed = true;
      }
      if (per.includes("readOrder") || role == "Super Admin") {
        permission.isOrderViewAllowed = true;
      }
    });
    return {
      jobBoardData,
      statusData,
      getJobBoard,
      getTradingPartners,
      selectDays,
      total,
      router,
      loading,
      refreshLoader,
      filterData,
      permission,
      formatDate,
      dateIsValid,
      startDrag,
      onDrop,
      detailPage,
      tradingPartnerData,
      generalConstants
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>